<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <div @click="launchFilePicker()">
      <slot name="activator"></slot>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input type="file"
       ref="file"
       :name="uploadFieldName"
       @change="onFileChange(
          $event.target.name, $event.target.files)"
       style="display:none">
    <!-- error dialog displays any potential error messages -->
    <v-dialog v-model="errorDialog" max-width="300">
      <v-card>
        <v-card-text class="subheading">{{errorText}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="errorDialog = false">Entiendo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Compressor from 'compressorjs'
import swal from 'sweetalert'
  export default {
    name: 'image-input',
    data: ()=> ({
      errorDialog: null,
      errorText: '',
      uploadFieldName: 'file',
      maxSize: 1024,
      imagen: null,
    }),
    props: {
    // Use "value" to enable using v-model
      value: Object,
    },
    methods: {
      launchFilePicker(){
        this.$refs.file.click();
      },
      onFileChange(fieldName, file) {
        const { maxSize } = this
        var imageFile = file[0]
        if (file.length>0) {
          let size = imageFile.size / maxSize / maxSize
          if (!imageFile.type.match('image.*')) {
            // check whether the upload is an image
            //this.errorDialog = true
            //this.errorText = 'Seleccione solo un archivo de Imagen'
            swal('',`Seleccione solo un archivo de Imagen`,'error');
          } else if (size>1) {
            // check whether the size is greater than the size limit
            //this.errorDialog = true
            //this.errorText = 'Su imagen es muy grande, no debe exceder de los 2Mb'
            swal('',`La imagen no debe superar 1Mb favor intente de nuevo`,'error');
          } else {
              var up = this
              new Compressor(imageFile, {
              quality: 0.3,
                success(result) {
                  let formData = new FormData()
                  let imageFile = result
                  let imageURL = URL.createObjectURL(imageFile)
                  formData.append(fieldName, imageFile)
                  up.$emit('input', { imageFile, imageURL })                  
                }
              })
          }
        }
      }
    }
  }
</script>